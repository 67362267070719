import React,{useEffect} from "react"
import { Link } from "gatsby"
import { useContentfulSite } from "hooks/use-contentful-site"
import PaddedContent from "components/paddedContent"
import ContentfulLink from "components/contentfulLink"

import styles from "styles/components/footer/footer.module.scss"
import Logo from "images/logos/vintrace-footer-logo.svg"

import icons from "helpers/icons"
import { getNewLink } from "variables.js"
const Footer = ({ site }) => {
  const siteLinks = useContentfulSite()
  if(!site){
    site = siteLinks
  }
  const listSocials = [
    { icon: 'youtube' },
    { icon: 'instagram' },
    { icon: 'twitter' },
    { icon: 'facebook' },
    { icon: 'linkedin' },
  ]
  useEffect(() => {
    const isBrowser = typeof window !== "undefined"
    if(isBrowser&&document.getElementById("hs_show_banner_button")){
      document.getElementById("hs_show_banner_button").style.display="none"
    }
  })
  return (
    <footer>
      <div className={styles.wrapper}>
        <PaddedContent>
          <div className={styles.content}>
            <div className={`${styles.linksContainer} ${styles.topFooter}`}>
              <a href={siteLinks?.contactLink} aria-label="Contact" target="_blank">
              Support
              </a>
              <a href={siteLinks.loginLink} aria-label="Login">
                {site.headerLinks[site.headerLinks.length-1]?.title}
              </a>
            </div>

            <div className={styles.linksContainer}>
              <div className={styles.linkSection + ' ' + styles.logoSection}>
                <Link to={getNewLink("/home")} className={styles.logo} aria-label="Home">
                  <Logo />
                </Link>
                <div className={styles.des}>{site.footerContent.footerContent}</div>
                <div className={styles.hideMb}>
                  <a href={site.footerLink?.externalLink} aria-label="Encompass">
                    <button className={styles.more}>{site.footerLink?.linkText}</button>
                  </a>
                </div>
              </div>
              <div className={styles.listSection}>
                {site.footerLinks.map((list, i) => (
                  <div key={i} className={styles.listPrItem}>
                    <p className={styles.title}>
                      {list.internalLink ?
                        <ContentfulLink isHeader="true" {...list}>
                          {list.linkText}
                        </ContentfulLink> : list.linkText}
                    </p>
                    <ul className={styles.list}>
                      {list.sublinks && list.sublinks.map(link => (
                        <li className={styles.listItem} key={`link-${link.id}`}>
                          <ContentfulLink isHeader="true" {...link} className={styles.link}>
                            {link.linkText}
                          </ContentfulLink>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.showMb + " " + styles.ctaButton}>
              <a href={site.footerLink?.externalLink} aria-label="Encompass">
                <button className={styles.more}>{site.footerLink?.linkText}</button>
              </a>
            </div>
            <div className={styles.linksContainer + ' ' + styles.socialIcons}>
              {listSocials.reduce((resultArr, social, socialIdx) => {
                if (siteLinks[`${social.icon}Link`]) {
                  resultArr.push(<a key={socialIdx} href={siteLinks[`${social.icon}Link`]} rel="noopener noreferrer" target="_blank">{icons[social.icon]}</a>)
                }
                return resultArr
              }, [])
              }
            </div>

            <div className={styles.footerText}>
              <p>{site.footerText.footerText}</p>
            </div>
          </div>
        </PaddedContent>
      </div>
    </footer >
  )
}

export default Footer
